@charset "UTF-8";
.ui-widget {
  font-size: 1em;
}
  .btn-custom {
    padding: 0.75rem !important;
  }
.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-size: 1em;
  }

  .ui-widget :active {
    outline: none; }

.ui-widget-content {
  border: 1px solid #D5D5D5;
  background-color: #ffffff;
  color: #222222; }
  .ui-widget-content a {
    color: #222222; }

.ui-widget-header {
  border: 1px solid #d9d9d9;
  background-color: #f0f3f5;
  color: #1b1d1f;
  font-weight: normal; }
  .ui-widget-header a {
    color: #1b1d1f; }

.ui-widget-overlay {
  background-color: #666666;
  opacity: .50;
  filter: Alpha(Opacity=50); }

.ui-state-default {
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  color: #555555; }
  .ui-state-default a {
    color: #555555; }

.ui-state-active {
  border-color: #bebebe;
  background-color: #d6d6d6;
  color: #212121; }
  .ui-state-active a {
    color: #212121; }

.ui-state-highlight {
  border-color: #156090;
  background-color: #186ba0;
  color: #FFFFFF; }
  .ui-state-highlight a {
    color: #FFFFFF; }

.ui-state-focus {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-state-focus a {
    color: #212121; }

.ui-state-error {
  border-color: #f44336;
  background-color: #f5554a;
  color: #cd0a0a; }
  .ui-state-error a {
    color: #cd0a0a; }

.ui-state-disabled,
.ui-widget:disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important; }
  .ui-state-disabled *,
  .ui-widget:disabled * {
    cursor: default !important; }

/* Forms */
.ui-inputtext {
  background-color: #ffffff;
  color: #222222; }

.ui-inputtext:enabled:hover {
  border-color: #c0c0c0; }

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
  outline: 0 none;
  border-color: #c0c0c0;
  -moz-box-shadow: 0px 0px 5px #c0c0c0;
  -webkit-box-shadow: 0px 0px 5px #c0c0c0;
  box-shadow: 0px 0px 5px #c0c0c0; }

.ui-inputgroup .ui-inputgroup-addon {
  border-color: #d6d6d6;
  background-color: #f0f0f0;
  color: #222222; }
  .ui-inputgroup .ui-inputgroup-addon:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .ui-inputgroup .ui-inputgroup-addon:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
.ui-inputgroup .ui-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }
.ui-inputgroup .ui-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.ui-float-label input.ng-dirty.ng-invalid ~ label {
  color: #cd0a0a; }

.ui-autocomplete .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
  border-color: #c0c0c0; }
.ui-autocomplete .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  border-color: #c0c0c0; }

.ui-chips > ul:not(.ui-state-disabled):hover {
  border-color: #c0c0c0; }
.ui-chips > ul:not(.ui-state-disabled).ui-state-focus {
  border-color: #c0c0c0; }

.ui-button:focus,
.ui-button:enabled:hover,
.ui-fileupload-choose:not(.ui-state-disabled):hover {
  outline: 0 none;
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-button:focus a,
  .ui-button:enabled:hover a,
  .ui-fileupload-choose:not(.ui-state-disabled):hover a {
    color: #212121; }

.ui-button:enabled:active,
.ui-fileupload-choose:not(.ui-state-disabled):active {
  border-color: #bebebe;
  background-color: #d6d6d6;
  color: #212121; }

.ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #212121; }

.ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #212121; }

.ui-dropdown .ui-dropdown-clear-icon {
  color: #888888; }

.ui-dropdown:not(.ui-state-disabled):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-dropdown:not(.ui-state-disabled):hover a {
    color: #212121; }

.ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):hover a {
    color: #212121; }

.ui-listbox .ui-listbox-header .ui-listbox-filter-container .fa {
  color: #222222; }
.ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):hover a {
    color: #212121; }
.ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-active):hover {
  border-color: #d6d6d6;
  background-color: #ffffff;
  color: #555555; }

.ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-multiselect:not(.ui-state-disabled):hover a {
    color: #212121; }

.ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):hover a {
    color: #212121; }

.ui-multiselect-panel .ui-multiselect-close {
  color: #1b1d1f; }

.ui-multiselect-panel .ui-multiselect-filter-container .fa {
  color: #222222; }

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover a {
    color: #212121; }

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:active {
  border-color: #bebebe;
  background-color: #d6d6d6;
  color: #212121; }

.ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #212121; }

.ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #212121; }

.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #212121; }

.ui-paginator a {
  color: #555555; }

.ui-datatable .ui-rowgroup-header a {
  color: #1b1d1f; }
.ui-datatable .ui-sortable-column:not(.ui-state-active):hover {
  background-color: #eeeeee;
  color: #212121; }
.ui-datatable .ui-row-toggler {
  color: #222222; }
.ui-datatable tbody.ui-datatable-hoverable-rows > tr.ui-widget-content:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #eeeeee;
  color: #212121; }

.ui-orderlist .ui-orderlist-item:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-orderlist .ui-orderlist-item:not(.ui-state-highlight):hover a {
    color: #212121; }

.ui-picklist .ui-picklist-item:not(.ui-state-disabled):not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-picklist .ui-picklist-item:not(.ui-state-disabled):not(.ui-state-highlight):hover a {
    color: #212121; }
.ui-picklist .ui-picklist-droppoint-highlight {
  border-color: #156090;
  background-color: #186ba0;
  color: #1f1f1f; }
  .ui-picklist .ui-picklist-droppoint-highlight a {
    color: #1f1f1f; }
.ui-picklist .ui-picklist-highlight {
  border-color: #156090;
  color: #1f1f1f; }
  .ui-picklist .ui-picklist-highlight a {
    color: #1f1f1f; }

.ui-tree.ui-treenode-dragover {
  border-color: #156090; }
.ui-tree .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-tree .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover a {
    color: #212121; }
.ui-tree .ui-treenode-content.ui-treenode-dragover {
  background-color: #d6d6d6;
  color: #212121; }
.ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit; }
.ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover a {
    color: #212121; }

.ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover a {
    color: #212121; }

.ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
    color: #212121; }

.ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend:hover a {
    color: #212121; }

.ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover a {
    color: #212121; }

.ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
    color: #212121; }

.ui-dialog .ui-dialog-titlebar-icon {
  color: #1b1d1f; }
  .ui-dialog .ui-dialog-titlebar-icon:hover {
    border-color: #c0c0c0;
    background-color: #eeeeee;
    color: #212121; }
    .ui-dialog .ui-dialog-titlebar-icon:hover a {
      color: #212121; }

.ui-sidebar .ui-sidebar-close {
  color: #1b1d1f; }
  .ui-sidebar .ui-sidebar-close:hover {
    border-color: #c0c0c0;
    background-color: #eeeeee;
    color: #212121; }
    .ui-sidebar .ui-sidebar-close:hover a {
      color: #212121; }

.ui-overlaypanel .ui-overlaypanel-close:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-overlaypanel .ui-overlaypanel-close:hover a {
    color: #212121; }

.ui-inplace .ui-inplace-display:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-inplace .ui-inplace-display:hover a {
    color: #212121; }

.ui-breadcrumb a {
  color: #1b1d1f; }

.ui-menuitem .ui-menuitem-link {
  color: #222222; }
  .ui-menuitem .ui-menuitem-link:hover {
    border-color: #c0c0c0;
    background-color: #eeeeee;
    color: #212121;
    border-color: transparent; }
    .ui-menuitem .ui-menuitem-link:hover a {
      color: #212121; }
.ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121;
  border-color: transparent; }
  .ui-menuitem.ui-menuitem-active > .ui-menuitem-link a {
    color: #212121; }

.ui-tabmenu .ui-tabmenu-nav li:not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-tabmenu .ui-tabmenu-nav li:not(.ui-state-active):hover a {
    color: #212121; }

.ui-steps .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-steps .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover a {
    color: #212121; }

.ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121;
  border-color: #d6d6d6; }
  .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover a {
    color: #212121; }
  .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover a {
    color: #212121; }
.ui-panelmenu .ui-panelmenu-header.ui-state-active a {
  color: #212121; }
.ui-panelmenu .ui-panelmenu-content .ui-menuitem-link {
  color: #222222; }
  .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover {
    border-color: #c0c0c0;
    background-color: #eeeeee;
    color: #212121;
    border-color: transparent; }
    .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover a {
      color: #212121; }

.ui-datepicker .ui-datepicker-header a {
  color: #1b1d1f; }
  .ui-datepicker .ui-datepicker-header a:hover {
    border-color: #c0c0c0;
    background-color: #eeeeee;
    color: #212121; }
    .ui-datepicker .ui-datepicker-header a:hover a {
      color: #212121; }
.ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover a {
    color: #212121; }
.ui-datepicker.ui-datepicker-monthpicker .ui-datepicker-month-cell:hover {
  border-color: #c0c0c0;
  background-color: #eeeeee;
  color: #212121; }
  .ui-datepicker.ui-datepicker-monthpicker .ui-datepicker-month-cell:hover a {
    color: #212121; }

.fc .fc-toolbar .fc-prev-button .ui-icon-circle-triangle-w {
  margin-top: .3em;
  background: none !important;
  font-family: 'primeicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center; }
  .fc .fc-toolbar .fc-prev-button .ui-icon-circle-triangle-w:before {
    content: ""; }
.fc .fc-toolbar .fc-next-button .ui-icon-circle-triangle-e {
  margin-top: .3em;
  background: none !important;
  font-family: 'primeicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center; }
  .fc .fc-toolbar .fc-next-button .ui-icon-circle-triangle-e:before {
    content: ""; }

.ui-rating a {
  color: #222222; }

.ui-organizationchart .ui-organizationchart-line-down {
  background-color: #bcbcbc; }
.ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #bcbcbc; }
.ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #bcbcbc; }
.ui-organizationchart .ui-organizationchart-node-content {
  border-color: #bcbcbc; }
.ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  color: #bcbcbc; }

/* TurboTable */
.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tfoot > tr > td {
  background-color: #f6f7f9;
  border: 1px solid #d9d9d9;
  color: #1b1d1f; }
.ui-table .ui-table-tbody > tr {
  background-color: #ffffff;
  color: #222222; }
  .ui-table .ui-table-tbody > tr > td {
    border: 1px solid #D5D5D5;
    background-color: inherit; }
  .ui-table .ui-table-tbody > tr.ui-state-highlight {
    background-color: #186ba0;
    color: #FFFFFF; }
  .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
    background-color: #3ca1e2;
    color: #FFFFFF; }
.ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #1b1d1f; }
.ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #186ba0;
  color: #FFFFFF; }
  .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #FFFFFF; }
.ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #eeeeee;
  color: #212121; }
  .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
    color: #212121; }
.ui-table.ui-table-hoverable-rows .ui-table-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eeeeee;
  color: #212121; }

@media (max-width: 35em) {
  .ui-table-responsive .ui-table-tbody > tr {
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5; } }
/* Row Reorder */
.ui-table .ui-table-tbody > tr.ui-table-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #186ba0; }

.ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #186ba0; }

/* TurboTable */
.ui-treetable .ui-treetable-thead > tr > th,
.ui-treetable .ui-treetable-tfoot > tr > td {
  background-color: #f6f7f9;
  border: 1px solid #d9d9d9;
  color: #1b1d1f; }
.ui-treetable .ui-treetable-tbody > tr {
  background-color: #ffffff;
  color: #222222; }
  .ui-treetable .ui-treetable-tbody > tr > td {
    border: 1px solid #D5D5D5;
    background-color: inherit; }
  .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
    background-color: #186ba0;
    color: #FFFFFF; }
    .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight .ui-treetable-toggler {
      color: #FFFFFF; }
  .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
    background-color: #3ca1e2;
    color: #FFFFFF; }
    .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected .ui-treetable-toggler {
      color: #FFFFFF; }
  .ui-treetable .ui-treetable-tbody > tr .ui-treetable-toggler {
    color: #222222; }
.ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #1b1d1f; }
.ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #186ba0;
  color: #FFFFFF; }
  .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #FFFFFF; }
.ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #eeeeee;
  color: #212121; }
  .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
    color: #212121; }
.ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #eeeeee;
  color: #212121; }

@media (max-width: 35em) {
  .ui-treetable-responsive .ui-treetable-tbody > tr {
    border-top: 1px solid #D5D5D5;
    border-bottom: 1px solid #D5D5D5; } }
/* Row Reorder */
.ui-treetable .ui-treetable-tbody > tr.ui-treetable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #186ba0; }

.ui-treetable .ui-treetable-tbody > tr.ui-treetable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #186ba0; }

/* Validation */
.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
p-chips.ng-dirty.ng-invalid > .ui-inputtext,
p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border-bottom-color: #f44336; }

/* Cornering */
.ui-corner-tl {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px; }

.ui-corner-tr {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }

.ui-corner-bl {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.ui-corner-br {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }

.ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/roboto-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("fonts/roboto-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-v15-latin-regular.woff2") format("woff2"), url("fonts/roboto-v15-latin-regular.woff") format("woff"), url("fonts/roboto-v15-latin-regular.ttf") format("truetype"), url("fonts/roboto-v15-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }
.ui-widget-header {
  background: #f0f3f5 0 0 repeat-x;
 }

.ui-accordion .ui-accordion-header {
  background: #f6f7f9;
  border-top: 1px solid #d9d9d9; }
  .ui-accordion .ui-accordion-header a {
    color: #1b1d1f; }
  .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover {
    background: #ededf0; }
  .ui-accordion .ui-accordion-header.ui-state-active {
    background: #ffffff;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0; }
.ui-accordion .ui-accordion-content {
  border: 0 none; }

/* Tabview */
.ui-tabview.ui-widget-content {
  border: 0 none; }
.ui-tabview .ui-tabview-nav {
  background: transparent; }
  .ui-tabview .ui-tabview-nav > li.ui-state-default {
    background: #f6f7f9; }
  .ui-tabview .ui-tabview-nav > li.ui-state-active {
    background: #ffffff;
    font-weight: normal;
    color: #555555; }
  .ui-tabview .ui-tabview-nav > li:not(.ui-state-active):not(.ui-state-disabled):hover {
    background: #ededf0; }

/* Spinner */
.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff; }

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff; }

.ui-slider {
  position: relative;
  text-align: left;
  background: #838688;
  border: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset; }

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 17px !important;
  height: 21px  !important;
  cursor: default;
  background: url("images/slider_handles.png") 0 0 no-repeat;
  outline: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: none; }

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background: #14a4ff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.ui-slider .ui-slider-handle.ui-state-active {
  background-position: -17px 0; }

.ui-slider-horizontal {
  height: 6px; }

.ui-slider-horizontal .ui-slider-handle {
  top: -2px !important;
  margin-left: -.6em; }

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

.ui-slider-horizontal .ui-slider-range-min {
  left: 0; }

.ui-slider-horizontal .ui-slider-range-max {
  right: 0; }

.ui-slider-vertical {
  width: .8em;
  height: 100px; }

.ui-slider-vertical .ui-slider-handle {
  left: -.1em !important;
  margin-left: 0;
  margin-bottom: -.6em; }

.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%; }

.ui-slider-vertical .ui-slider-range-min {
  bottom: 0; }

.ui-slider-vertical .ui-slider-range-max {
  top: 0; }

.ui-progressbar.ui-progressbar-determinate .ui-progressbar-value {
  border: 0 none;
  background: #8ec5fc; }
.ui-progressbar.ui-progressbar-determinate .ui-progressbar .ui-progressbar-label {
  color: #222222; }
.ui-progressbar.ui-progressbar-indeterminate {
  background: #8ec5fc; }
  .ui-progressbar.ui-progressbar-indeterminate .ui-progressbar-value {
    border: 0 none;
    background-color: #186ba0; }

.ui-widget-header .ui-button,
.ui-widget-content .ui-button,
.ui-widget.ui-button,
.ui-button {
  border: 1px solid #2399e5;
  color: #FFFFFF;
  background: #2399e5;
  -webkit-transition: background-color .2s;
  -moz-transition: background-color .2s;
  transition: background-color .2s; }
  .ui-widget-header .ui-button:enabled:hover, .ui-widget-header .ui-button:focus,
  .ui-widget-content .ui-button:enabled:hover,
  .ui-widget-content .ui-button:focus,
  .ui-widget.ui-button:enabled:hover,
  .ui-widget.ui-button:focus,
  .ui-button:enabled:hover,
  .ui-button:focus {
    border: 1px solid #1f89ce;
    background: #1f89ce;
    outline: 0 none;
    color: #ffffff; }
  .ui-widget-header .ui-button:enabled:active,
  .ui-widget-content .ui-button:enabled:active,
  .ui-widget.ui-button:enabled:active,
  .ui-button:enabled:active {
    border: 1px solid #156090;
    background: #186ba0;
    color: #ffffff; }

.ui-fileupload-choose:not(.ui-state-disabled):hover,
.ui-fileupload-choose.ui-state-focus {
  border: 1px solid #1f89ce;
  background: #1f89ce;
  outline: 0 none;
  color: #ffffff; }

.ui-fileupload-choose:not(.ui-state-disabled):active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff; }

/* Checkbox and Radio */
.ui-chkbox-box.ui-state-active,
.ui-radiobutton-box.ui-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #FFFFFF; }

.ui-chkbox-box.ui-state-focus,
.ui-radiobutton-box.ui-state-focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce; }

.ui-chkbox-box.ui-state-focus.ui-state-active {
  background: #186ba0; }

/* Inputs */
.ui-inputtext {
  background: #ffffff;
  color: #222222;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  transition: .2s; }

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce; }

/* InputSwitch */
.ui-inputswitch-on {
  background: #186ba0 !important;
  color: #ffffff !important; }

.ui-paginator .ui-paginator-page.ui-state-active {
  background: #186ba0;
  color: #ffffff;
  border-color: #156090; }

/* DataTable  */
.ui-datatable th.ui-state-default {
  background: #ebedf0;
  border-color: #d9d9d9; }
.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
  background: #d3d5d8;
  border-color: #d9d9d9; }
.ui-datatable th.ui-state-active {
  background: #186ba0;
  color: #ffffff; }
.ui-datatable tbody > tr.ui-widget-content {
  border-color: #d9d9d9; }
  .ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd {
    background-color: #fafafb; }
  .ui-datatable tbody > tr.ui-widget-content.ui-state-highlight {
    background-color: #186ba0;
    color: #FFFFFF; }
.ui-datatable tfoot td.ui-state-default {
  background: #ebedf0;
  border-color: #d9d9d9; }

/* TurboTable */
.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tfoot > tr > td {
  background-color: #ebedf0;
  border-color: #d9d9d9; }
.ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #d3d5d8;
  border-color: #d9d9d9; }
.ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #fafafb; }
  .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
    background-color: #186ba0;
    color: #FFFFFF; }
  .ui-table .ui-table-tbody > tr:nth-child(even).ui-contextmenu-selected {
    background-color: #3ca1e2;
    color: #FFFFFF; }

/* TurboTable */
.ui-treetable .ui-treetable-thead > tr > th,
.ui-treetable .ui-treetable-tfoot > tr > td {
  background-color: #ebedf0;
  border-color: #d9d9d9; }
.ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #d3d5d8;
  border-color: #d9d9d9; }

/* Panel */
.ui-panel.ui-widget {
  padding: 0; }
  .ui-panel.ui-widget .ui-panel-titlebar.ui-corner-all {
    -moz-border-radius-bottom-left: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottom-right: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ui-panel.ui-widget .ui-panel-titlebar {
    border-width: 0 0 1px 0; }
  .ui-panel.ui-widget .ui-panel-titlebar-icon span {
    position: relative;
    top: 1px; }

/* TreeTable */
.ui-treetable th.ui-state-default {
  background: #ebedf0;
  border-color: #d9d9d9; }

/* ButtonSet */
.ui-togglebutton.ui-button.ui-state-default,
.ui-selectbutton .ui-button.ui-state-default {
  border: 1px solid #d6d6d6;
  background: #ffffff;
  font-weight: normal;
  color: #555555; }

.ui-togglebutton.ui-button.ui-state-hover, .ui-togglebutton.ui-button.ui-state-focus,
.ui-selectbutton .ui-button.ui-state-hover, .ui-selectbutton .ui-button.ui-state-focus, .ui-selectbutton .ui-button:focus {
  border: 1px solid #c0c0c0;
  background: #eeeeee;
  font-weight: normal;
  color: #212121; }

.ui-togglebutton.ui-button.ui-state-focus,
.ui-selectbutton .ui-button.ui-state-focus.ui-state-active,
.ui-selectbutton .ui-button.ui-state-active:focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce; }

.ui-togglebutton.ui-button.ui-state-active,
.ui-selectbutton .ui-button.ui-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #FFFFFF; }

.ui-multiselect .ui-multiselect-label {
  background-color: #ffffff; }

.ui-dropdown.ui-state-focus, .ui-multiselect.ui-state-focus {
  -moz-box-shadow: 0px 0px 5px #1f89ce;
  -webkit-box-shadow: 0px 0px 5px #1f89ce;
  box-shadow: 0px 0px 5px #1f89ce; }

/* Growl */
.ui-growl-item-container.ui-state-highlight.ui-growl-message-info {
  background-color: #2196f3;
  border-color: #2196f3; }
.ui-growl-item-container.ui-state-highlight.ui-growl-message-error {
  background-color: #f44336;
  border-color: #f44336; }
.ui-growl-item-container.ui-state-highlight.ui-growl-message-warn {
  background-color: #FFB300;
  border-color: #FFB300; }
.ui-growl-item-container.ui-state-highlight.ui-growl-message-success {
  background-color: #4CAF50;
  border-color: #4CAF50; }

/* TabMenu */
.ui-tabmenu {
  border: 0 none; }
  .ui-tabmenu .ui-tabmenu-nav {
    background: none; }
    .ui-tabmenu .ui-tabmenu-nav > li.ui-state-default {
      background: #f6f7f9; }
    .ui-tabmenu .ui-tabmenu-nav > li.ui-state-active {
      background: #ffffff;
      font-weight: normal;
      color: #555555; }
    .ui-tabmenu .ui-tabmenu-nav > li:not(.ui-state-active):not(.ui-state-disabled):hover {
      background: #ededf0; }

/* Menus */
.ui-menu,
.ui-menubar, .ui-menubar .ui-submenu-list,
.ui-tieredmenu, .ui-tieredmenu .ui-submenu-list,
.ui-slidemenu, .ui-slidemenu .ui-submenu-list,
.ui-contextmenu, .ui-contextmenu .ui-submenu-list,
.ui-megamenu {
  color: #1b1d1f;
  background: #f6f7f9 0 0 repeat-x;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f7f9), color-stop(100%, #ebedf0));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* Opera11.10+ */
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
  /* W3C */ }

.ui-menu .ui-menuitem > .ui-menuitem-link:hover,
.ui-menubar .ui-menuitem > .ui-menuitem-link:hover,
.ui-tieredmenu .ui-menuitem > .ui-menuitem-link:hover,
.ui-slidemenu .ui-menuitem > .ui-menuitem-link:hover,
.ui-contextmenu .ui-menuitem > .ui-menuitem-link:hover,
.ui-megamenu .ui-menuitem > .ui-menuitem-link:hover {
  background-color: #a6a6a6;
  color: #ffffff; }

.ui-menu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link,
.ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link,
.ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link,
.ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link,
.ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link,
.ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #a6a6a6;
  color: #ffffff; }

/* PanelMenu */
.ui-panelmenu .ui-panelmenu-header.ui-state-active,
.ui-panelmenu .ui-panelmenu-header.ui-state-active a {
  border-color: #156090;
  background: #186ba0;
  color: #FFFFFF; }

/* DatePicker */
.ui-datepicker.ui-widget {
  padding: 0; }
  .ui-datepicker.ui-widget .ui-datepicker-header {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none; }
    .ui-datepicker.ui-widget .ui-datepicker-header a:hover {
      border-width: 1px; }
  .ui-datepicker.ui-widget .ui-datepicker-calendar {
    margin: 0; }
    .ui-datepicker.ui-widget .ui-datepicker-calendar thead th {
      background-color: #f6f8fa; }
    .ui-datepicker.ui-widget .ui-datepicker-calendar td {
      border-bottom: 1px solid rgba(213, 213, 213, 0.5); }
      .ui-datepicker.ui-widget .ui-datepicker-calendar td a {
        border: 0 none;
        text-align: center; }
        .ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-highlight {
          background-color: #d6d6d6;
          color: #212121; }
        .ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active {
          background-color: #186ba0;
          color: #ffffff; }
    .ui-datepicker.ui-widget .ui-datepicker-calendar tr:last-child td {
      border-bottom: 0 none; }
  .ui-datepicker.ui-widget .ui-datepicker-month-picker-container a.ui-state-active {
    background-color: #186ba0;
    color: #ffffff; }
  .ui-datepicker.ui-widget .ui-timepicker {
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
  .ui-datepicker.ui-widget.ui-datepicker-timeonly .ui-timepicker {
    border-top: 0 none; }

/* Steps */
.ui-steps .ui-steps-item.ui-state-highlight .ui-menuitem-link {
  color: #ffffff; }

/* Dialog */
.ui-dialog.ui-widget .ui-dialog-titlebar {
  padding: 1em 1.5em; }

.ui-dialog.ui-widget .ui-dialog-titlebar .ui-dialog-title {
  font-size: 1.25em; }

.ui-dialog.ui-widget .ui-dialog-content {
  padding: 1em 1.5em; }

/* Schedule */
.fc .fc-button-group .ui-state-active {
  border: 1px solid #156090;
  background: #186ba0;
  color: #ffffff; }

  .pi-sort-alt:before {
    content: "\e99e";
  }
  .status .p-tieredmenu {
    /* top: -88px!important; */
    width:140px;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #EFF6FF;
  color: #1D4ED8;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #1D4ED8;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #1D4ED8;
}
.sidebar .nav-link.active .nav-icon {
  color: rgb(2, 109, 218) !important;
}
