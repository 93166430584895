body {
  background-color: rgb(249 249 249);
  font-family: 'Arial' !important;
  font-size: 16px;
}

label {
  margin-bottom: 0;
}

.app-header .navbar-brand {
  width: 200px;
}

button[appasidemenutoggler] {
  display: none;
}

.bg-light,
.footer {
  background: none !important;
}

.sidebar-nav .nav-group.show {
  background: #fff;
}

.app-header .navbar-toggler[appsidebartoggler] {
  display: none !important;
}

@media (min-width: 992px) {
  .app-header .navbar-toggler[appsidebartoggler] {
    display: block !important;
  }
}

.bg-transparent {
  background: transparent;
}

.sidebar {
  color: rgb(49 51 52);
  background: #ffffff;
}

.sidebar .nav-link {
  color: rgb(49 51 52);
  border-radius: 8px;
  padding: 8px 4px;
}

.sidebar .nav-link.active,
.sidebar-nav .nav-group.show .nav-group-toggle {
  color: #fff;
  background: rgb(27 151 243);
}

.sidebar .nav-link:hover {
  color: #fff !important;
  background: rgb(27 151 243);
}

.sidebar .nav-item {
  padding: 5px 10px;
}

.sidebar-nav .nav-group-toggle::after {
  // background-color: rgb(2, 109, 218);
  margin-right: 10px;
  background-image: var(--cui-sidebar-toggler-indicator);
  transform: rotate(270deg);
}

.nav-group.show .nav-group-toggle::after {
  background-image: var(--cui-sidebar-nav-group-indicator);
}

.nav-group .nav-group-items {
  background-color: #eaf6fc;
}

.sidebar-nav .nav-group-items .nav-link {
  margin-top: -19px;
}

.sidebar-nav .nav-group-items .nav-link .nav-icon {
  margin-left: -55px !important;
}

.sidebar .nav-link.active .nav-icon,
.sidebar-nav .nav-group.show .nav-group-toggle .nav-icon {
  color: #fff;
}

.sidebar-nav .nav-group .nav-group-toggle .nav-icon {
  margin-left: -3px !important;
}

.sidebar .nav-link .nav-icon {
  color: rgb(2 109 218);
  font-size: 20px;
  width: 1.4rem;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.sidebar .nav-link:hover .nav-icon {
  color: #fff;
}

.sidebar>.sidebar-nav {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sidebar-nav .nav-group-items .nav-link.active {
  color: rgb(2 109 218) !important;
  background: none !important;
}

.sidebar-toggler {
  background: #fff;
}

@media (min-width: 992px) {
  .sidebar-brand {
    background: #fff;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%);
  }

  .sidebar-minimized.sidebar-fixed .sidebar {
    width: 70px;
  }

  .sidebar-minimized .sidebar .sidebar-nav {
    overflow: visible;
    width: 70px;
  }

  .sidebar-minimized .sidebar .nav {
    width: 70px;
  }

  .sidebar-minimized .sidebar .nav-item {
    width: 70px;
  }

  .brand-minimized .app-header .navbar-brand {
    width: 70px;
  }

  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 70px;
  }

  .sidebar-minimized .sidebar .sidebar-minimizer {
    width: 70px;
  }

  html:not([dir='rtl']) .sidebar-minimized .sidebar {
    margin-left: -70px;
  }

  html:not([dir='rtl']) .sidebar-lg-show .sidebar,
  html:not([dir='rtl']) .sidebar-show .sidebar {
    margin-left: 0;
  }
}

.sidebar .nav-link .nav-icon {
  margin: 0 1px;
}

.sidebar .nav-dropdown.open {
  background: #fff;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #313334;
}

.sidebar .nav-dropdown.open .nav-link.active {
  color: #fff;
}

app-sidebar-nav-dropdown app-sidebar-nav-link-content {
  margin-left: 16px;
}

app-sidebar-nav-dropdown .nav-link {
  margin-top: 5px;
}

app-sidebar-nav-dropdown app-sidebar-nav-link-content:before {
  content: '• ';
}

.p-formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.p-formgroup-inline .p-field,
.p-formgroup-inline .p-field-checkbox,
.p-formgroup-inline .p-field-radiobutton {
  margin-right: 1rem;
}

.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-tbody>tr>td {
  border: none;
}

.app-header {
  border-bottom: none;
}

.p-datatable .p-paginator-bottom {
  border: 0;
  background: transparent;
  margin: 8px 0 16px;
}

.p-fluid .p-button[type='submit'] {
  width: initial;
}

.top-mav {
  min-height: 60px;
}

.list-filter-field {
  width: 200px;
}

.list-filter {
  display: flex;
  align-items: flex-end;
}

.list-filter .p-button {
  width: initial;
}

.p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
  padding: 0.71375rem 4px;
  font-size: 14px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  font-size: 10px;
}

// .p-datatable .p-sortable-column:not(.p-highlight) p-sorticon {
//   display: none;
// }
.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
  padding: 0.71375rem 4px;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td:first-child,
.p-datatable.p-datatable-lg .p-datatable-thead>tr>th:first-child {
  padding-left: 10px;
}

.p-calendar .p-datepicker {
  min-width: 300px !important;
}

.p-tabview .p-tabview-panels {
  border: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: none;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.status-badge {
  font-size: 100%;
}

.status-button {
  &.p-splitbutton {
    width: 100px !important;
    max-width: 100px;
  }

  .p-button {
    padding: 5px !important;
  }

  .p-button-label {
    text-align: left;
  }
}

p-autocomplete.list-filter-field .p-button,
p-autocomplete.list-filter-field .p-button:enabled:active,
p-autocomplete.list-filter-field .p-button:enabled:hover {
  background: transparent;
  color: #848484;
  border-color: #a6a6a6;
  width: 2.357rem;
  border-left: none;
}

p-autocomplete.list-filter-field .p-autocomplete-input {
  border-right: none;
}

.paging-wraper {
  display: flex;
  margin: 16px auto;
}

.paging-wraper .page-report {
  color: #848484;
  padding: 0 0.5rem 0 0;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
  position: relative;
}

.paging-wraper p-paginator .p-paginator {
  background: transparent;
  border: none;
  width: fit-content;
}

.p-toast {
  z-index: 9999 !important;
}

.p-datepicker table td {
  padding: 0 !important;
}

.p-datepicker .p-datepicker-header,
.p-datepicker table th,
.p-datepicker table td {
  padding: 0 !important;
}

.p-datepicker table {
  margin: 0 !important;
}

.small-btn {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.loading-icon {
  background: rgba(255, 255, 255, 1);
  width: 200px;
  height: 200px;
  position: fixed;
  top: -100px;
  right: -100px;
  z-index: 999999;
  transform: rotate(45deg);
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.loading-icon i {
  position: absolute;
  top: 130px;
  right: 75px;
  font-size: 3rem;
  pointer-events: none;
}

.upload-wapper {
  position: relative;
}

.upload-wapper p-fileUpload {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.upload-wapper p-fileUpload div.p-fileupload {
  height: 100%;
}

.upload-wapper p-fileUpload .p-button.p-fileupload-choose {
  width: 100%;
  height: 100%;
}

.content-html {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 30px;
}

.content-html * {
  margin: 0;
}

.no-items-row {
  color: red;
  text-align: center;
  background-color: white;
  border: 1px solid #c8c8c8;
  height: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar-nav .nav-group {
  margin-top: 4px;
  margin-bottom: 4px;
}

// .p-tieredmenu{top: -88px!important}

.p-datatable>.p-datatable-wrapper {
  min-height: 300px;
}

.action {
  a {
    padding-left: 10px;
  }
}

.container {
  display: unset !important;
}

.navbar .d-flex button {
  margin-left: 5px;
}

.p-fieldset-toggleable .p-fieldset-legend a {
  justify-content: left !important;
}

.form-card {
  padding: 10px;
}

.row,
.card {
  font-family: 'Arial' !important;
  font-size: 16px;
}

.form-view {

  input,
  p-dropdown,
  .p-dropdown,
  p-splitbutton,
  .p-splitbutton.p-button-info>.p-button {
    pointer-events: none;
    background: aliceblue;
    border-color: aliceblue;
    color: #333;
  }
}

input.disabled,
p-dropdown.disabled,
.p-dropdown.disabled,
.p-disabled,
.p-component:disabled {
  pointer-events: none;
  background: aliceblue;
  border-color: aliceblue;
  color: #333;
}

input.p-component:disabled {
  opacity: 1;
}

// p-fieldset div.col-3 {
//   padding-top: 10px;
// }

input {
  padding: 0.429rem 0.429rem;
  border: 1px solid #a6a6a6;
}

.p-dropdown {
  width: 100%;
}

.consumer-detail {
  margin-top: 5px;

  p-calendar,
  .p-calendar,
  p-inputnumber,
  .p-inputnumber,
  .p-inputtext {
    width: 100%;
  }

  // width: 100%;
}

.bom .consumer-detail {

  .p-inputtext,
  .p-inputnumber-input {
    width: 120px;
  }

}

.tab-tree {
  .p-inputnumber-input {
    width: 100px;
  }
}

.td-tool-btn {
  .p-button {
    float: right;
  }
}

.tbl-title {
  width: 260px;
}

.frozenCols {
  .tbl-title {
    height: 83px;
  }

  .p-treetable .p-treetable-thead>tr>th.tbl-numb {
    height: 83px;
    width: 90px;
  }

  .p-treetable .p-treetable-tbody>tr>td.col-name,
  .p-treetable .p-treetable-tbody>tr>td.tbl-numb {
    height: 44px;
  }
}

.p-treetable .p-treetable-thead>tr>th.tbl-numb {
  width: 90px;
}

.p-treetable .p-treetable-tbody>tr>td.tbl-numb {
  width: 90px;
  text-align: center !important;

  input {
    width: 100% !important;
  }
}

.tbl-title input {
  width: 190px !important;
}



.p-treetable .p-treetable-tbody>tr>td {


  padding: 3px !important;
}